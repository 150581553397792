/* eslint-disable no-underscore-dangle */
import cn from 'clsx';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import imgRecycle from '../../images/stickers/sticker-recycle.svg';
import { getCurrentStore } from '../../services/auth';
import useFirebase from '../../services/firebase/useFirebase';
import { formatDateDDMMYYYY } from '../../utils/dates';
import { resolveUrl } from '../../utils/string';
import ButtonKitchen from '../components/ButtonKitchen';
import Image from '../components/Image';
// import StickerStock from '../components/StickerStock';
import MainLayout from '../layouts/MainLayout';
import './KitchenItem.styl';

const KitchenItem = ({ data, pageContext: { kitchenPath, kitchenSlug } }) => {
    const { kitchen, kitchens } = data;
    const firebase = useFirebase();
    const { id } = getCurrentStore();

    useEffect(() => {
        if (!firebase) return;

        const storeRef = firebase.database().ref(`/magasins/${id}`);

        const kitchenRef = firebase
            .database()
            .ref(`/magasins/${id}/kitchens/${kitchenSlug}`);

        kitchenRef.transaction((item) => {
            if (item) {
                return { ...item, nbClicks: item.nbClicks + 1 };
            }
            return item;
        });

        storeRef.transaction((item) => {
            if (item) {
                return {
                    ...item,
                    appVersion: process.env.__VERSION__,
                    lastTimeActive: formatDateDDMMYYYY(new Date()),
                };
            }
            return item;
        });
    }, [firebase, id, kitchenSlug]);

    const getPicture = (item, pictureslist) => {
        const img = pictureslist.edges.filter(
            (picture) => picture.node.name === item,
        )[0];

        return img.node.childImageSharp.fixed;
    };

    return (
        <MainLayout>
            <div className='kitchen-item-page'>
                <div className='top'>
                    <div className='content'>
                        <div className='left'>
                            <div className='title-bloc'>
                                <div className='title'>
                                    Cuisine <span>{kitchen.title}</span>
                                </div>
                                <div className='sub-title'>
                                    {kitchen.subtitle}
                                </div>
                            </div>
                            <div className='attributes'>
                                {kitchen.attributes.map((attr, i) => (
                                    <div
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`${kitchen.title}-attr-${i}`}
                                        className='attr-item'
                                    >
                                        <div>
                                            <i
                                                className={`icon icon-${kitchen.attributes_icons[i]}`}
                                            />
                                        </div>
                                        <span>{attr}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='description'>
                            <div className='intro'>
                                <i className='icon icon-detail' />
                                <span>PLUS PRODUIT</span>
                            </div>
                            <div>{kitchen.description}</div>
                            {kitchen.description_note && (
                                <div className='description-note'>
                                    {/* {kitchen.description_note} */}
                                    <img src={imgRecycle} alt='bla' />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='bottom'>
                    <div className='title-bloc'>
                        <div className='title'>
                            Choisissez votre <span>couleur</span>
                        </div>
                    </div>
                    <div
                        className={cn('gallery', {
                            small: kitchen.colors.length === 4,
                        })}
                    >
                        {kitchen.colors.map((color, i) => (
                            <Link
                                key={`${kitchen.title}-button-color-${color}`}
                                to={resolveUrl(
                                    kitchenPath,
                                    kitchen.colors_slugs[i],
                                )}
                            >
                                <Image
                                    fixed={getPicture(
                                        kitchen.colors_slugs[i],
                                        kitchens,
                                    )}
                                    alt={`cuisine-${kitchen.title}-${color}`}
                                    // imgStyle={{
                                    //     maxWidth:
                                    //         kitchens.edges.length > 3
                                    //             ? '376px'
                                    //             : '514px',
                                    // }}
                                />
                                <ButtonKitchen
                                    bgColor={kitchen.colors_hexa[i]}
                                    label={color}
                                />
                                {/* <StickerStock
                                    type={kitchen.colors_stickers[i]}
                                /> */}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

KitchenItem.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        kitchenPath: PropTypes.string.isRequired,
        kitchenSlug: PropTypes.string.isRequired,
        slugRegex: PropTypes.string.isRequired,
    }).isRequired,
};

export default KitchenItem;

export const query = graphql`
    query($kitchenSlug: String!, $slugRegex: String!) {
        kitchen: kitchensCsv(slug: { eq: $kitchenSlug }) {
            ...KitchenDetail
        }
        kitchens: allFile(
            filter: {
                name: { regex: $slugRegex }
                relativeDirectory: { eq: $kitchenSlug }
                sourceInstanceName: { eq: "kitchens_colors" }
            }
        ) {
            edges {
                node {
                    ...ImageKitchenColor
                }
            }
        }
    }
`;
